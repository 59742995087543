// **************LocalHost******************

// export const domain = 'http://localhost:4000';
// export const wssdomain = 'ws://localhost:4000';


// **************Production******************

// export const domain = 'https://daminigames.com';
// export const wssdomain = 'wss://daminigames.com';

// **************Production2******************

// export const domain = 'https://demo2.anotechsolutions.cloud';
// export const wssdomain = 'wss://demo2.anotechsolutions.cloud';

export const domain = 'https://indiansclub.online';
export const wssdomain = 'wss://indiansclub.online';